import { exportConfig } from "src/app/core/config/firebase";

export const environment = {
    production: false,
    baseUrlApi: 'https://develop-calypso-admin.imagineapps.co',
    urlBaseDapta: 'https://api.dapta.ai/api/calypso-del-caribe-169-817-8/',
    apikeyDapta: 'h6Y4J-12631411-eb41-4e62-958b-3dc5804ccffb-f',
    endPointDapta: 'https://api.dapta.ai/api/calypso-del-caribe-169-817-8/products_b2b?x-api-key=FXwUg-12631411-eb41-4e62-958b-3dc5804ccffb-a',
    firebaseConfig: exportConfig({
        apiKey: 'AIzaSyBFfJikc71dRhvSTgnFzF0Bqd0s2xN_bDU',
        authDomain: 'qmenu-qa.firebaseapp.com',
        databaseURL: 'https://qmenu-qa-default-rtdb.firebaseio.com/',
        projectId: 'qmenu-qa',
        storageBucket: 'qmenu-qa.appspot.com',
        messagingSenderId: '809251249564',
    }),
};
