import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { Subject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  isLoading = new Subject<boolean>();
  private completeUrl = `${environment.baseUrlApi}/collections`;


  constructor(
    private http: HttpClient
  ) { }

  generateUniqueId(): string {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substring(2);
    return dateString + randomness;
  };

  onlyNumbers(event: KeyboardEvent): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  showLoading(visible: boolean): void {
    this.isLoading.next(visible);
  }

  setFirebaseFilters(filters: any[], orderBy: string, limit: number) {
    let params = new HttpParams();
    if (filters.length > 0) {
      params = params.set('filters', JSON.stringify(filters));
    }

    // Añadir orderBy
    if (orderBy) {
      params = params.set('orderBy', orderBy);
    }

    // Añadir limit
    if (limit > 0) {
      params = params.set('limit', limit.toString());
    }
    return params;
  }

  getCollections(collectionName: string, filters: any[] = [], orderBy: string = '', limit: number = 0) {
    let params = this.setFirebaseFilters(filters, orderBy, limit);
    const response = this.http.get(`${this.completeUrl}/getCollection/${collectionName}`, { params });
    return lastValueFrom(response);
  }
}
